import * as yup from "yup";

function getValidationType(dataType, errorMessage) {
  switch (dataType) {
    case "number":
      return yup
        .number()
        .transform((value) => (Number.isNaN(value) ? -1 : value));
    case "email":
      return yup.string().email().typeError(errorMessage);
    case "date":
      return yup.date().typeError(errorMessage);
    default:
      return yup.string();
  }
}

/**
 *
 * @param {Array} currentPageQuestions
 * @returns yup.ObjectSchema
 */
export default function getFormSchema(currentPageQuestions) {
  return currentPageQuestions.reduce((schema, question) => {
    const {
      data_type: dataType,
      error_message: errorMessage,
      question_key: questionKey,
      required,
      validations,
    } = question;

    let validator = getValidationType(dataType, errorMessage);

    if (required) {
      validator = validator.required(errorMessage);
    }

    // eslint-disable-next-line no-unused-expressions
    validations?.forEach((validation) => {
      switch (validation?.rule) {
        case "regex":
          validator = validator.matches(
            new RegExp(validation?.value?.toString()),
            validation.error_message
          );
          break;
        case "min_length":
        case "min_value":
          validator = validator.min(validation.value, validation.error_message);
          break;
        case "max_length":
        case "max_value":
          validator = validator.max(validation.value, validation.error_message);
          break;
        default:
          break;
      }
    });

    return schema.concat(yup.object().shape({ [questionKey]: validator }));
  }, yup.object().shape({}));
}
