function getPreselectedValue(question) {
  return question.options?.find((option) => option.is_selected)?.value;
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/**
 *
 * @param {Object} application - current state of application
 * @param {Object} currentPageQuestions - current list of questions for the given page
 * @returns object with prefilled values
 */
export default function getFormInitialValues(
  application,
  currentPageQuestions
) {
  if (!isEmpty(application)) {
    return Object.fromEntries(
      Object.entries(application).map(([key, val]) => [key, val])
    );
  }

  return Object.entries(currentPageQuestions).reduce(
    (acc, [, question]) => ({
      ...acc,
      [question.question_key]: getPreselectedValue(question) || "",
    }),
    {}
  );
}
