export default function formatPhoneNumber(value) {
  if (!value) {
    return value;
  }

  if (value.length <= 10 || (value.length <= 11 && value[0] === "1")) {
    const regex =
      value[0] === "1"
        ? /(1{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/
        : /(\d{0,3})(\d{0,3})(\d{0,4})/;

    return (
      value
        .match(regex)
        ?.slice(1)
        .filter((match) => match.length)
        .join("-") || ""
    );
  }
  return value;
}
