import React from "react";
import PropTypes from "prop-types";

import formatPhoneNumber from "../../functions/formatPhoneNumber";

function InputPhone({ name, value, onChange, ...props }) {
  function handleChange(e) {
    const mockEvent = {
      target: {
        name,
        value: e.target.value?.replace(/-/g, ""),
      },
    };
    onChange(mockEvent);
  }

  return (
    <input
      {...props}
      value={formatPhoneNumber(value)}
      name={name}
      onChange={handleChange}
    />
  );
}

InputPhone.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default InputPhone;
