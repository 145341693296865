import React from "react";
import PropTypes from "prop-types";
import InputPhone from "./input-phone";
import InputBasic from "./input-basic";
import InputSelect from "./input-select";
import InputGroup from "./input-group";
import InputTextArea from "./input-text-area";

function DynamicFormContainer({
  errorMessage,
  name,
  onBlur,
  onChange,
  question,
  value,
  isValid,
}) {
  switch (question.question_type) {
    case "text": {
      return (
        <InputGroup
          component={InputBasic}
          question={question}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          errorMessage={errorMessage}
          isValid={isValid}
        />
      );
    }
    case "text_area": {
      return (
        <InputGroup
          component={InputTextArea}
          question={question}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="text_area"
          value={value}
          errorMessage={errorMessage}
          isValid={isValid}
        />
      );
    }
    case "email": {
      return (
        <InputGroup
          component={InputBasic}
          question={question}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="email"
          value={value}
          errorMessage={errorMessage}
          isValid={isValid}
        />
      );
    }
    case "number": {
      return (
        <InputGroup
          component={InputBasic}
          question={question}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="number"
          inputMode="numeric"
          value={value}
          errorMessage={errorMessage}
          isValid={isValid}
        />
      );
    }
    case "phone": {
      return (
        <InputGroup
          component={InputPhone}
          question={question}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          inputMode="numeric"
          value={value}
          errorMessage={errorMessage}
          isValid={isValid}
        />
      );
    }
    case "date_picker": {
      return (
        <InputGroup
          component={InputBasic}
          question={question}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="date"
          value={value}
          errorMessage={errorMessage}
          isValid={isValid}
        />
      );
    }
    case "dropdown": {
      const menuItems = question.options?.map((option) => ({
        label: option.title,
        value: option.value.toString(),
      }));

      return (
        <InputGroup
          component={InputSelect}
          question={question}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          errorMessage={errorMessage}
          menuItems={menuItems}
          isValid={isValid}
        />
      );
    }
    default: {
      console.error("unhandled question type: ", question.question_type);
      return null;
    }
  }
}

DynamicFormContainer.propTypes = {
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    title: PropTypes.string,
    placeholder: PropTypes.string,
    question_type: PropTypes.string,
    question_key: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    validations: PropTypes.arrayOf(
      PropTypes.shape({
        rule: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        error_message: PropTypes.string,
      })
    ),
    error_message: PropTypes.string,
    data_type: PropTypes.string,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
};

export default DynamicFormContainer;
